import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { InputText } from 'components/Forms/ReduxFormFields';
import { normalizeName } from 'components/Forms/ShoppingListName/normalize-name';
import { inputRequired } from 'components/Forms/ReduxFormFields/validation';

import styles from 'components/Lists/CreateList/Form/Form.scss';

const MAX_LENGTH = 20;
const validateInputRequired = inputRequired('Please enter a name for the new list');

const CreateListForm = ({ disabled, handleSubmit, placement }) => (
  <form
    className={classNames(styles.form, {
      [styles.disabled]: disabled,
      [styles.cardPlacement]: placement === 'card',
      [styles.modalPlacement]: placement === 'modal',
    })}
    data-testid="create-list-form"
    method="POST"
    onSubmit={handleSubmit}
  >
    <div className={styles.name} data-cs-mask>
      <InputText
        autofocus
        disabled={disabled}
        id="list-name"
        label="List name"
        maxlength={MAX_LENGTH}
        name="listName"
        normalize={normalizeName}
        required
        showCharacterCount
        validate={[validateInputRequired]}
      />
    </div>
    <div className={styles.submit}>
      <Button
        data-testid="list-name-submit"
        disabled={disabled}
        label="Create list"
        theme="primary"
        type="submit"
        width="fit"
      />
    </div>
  </form>
);

CreateListForm.defaultProps = {
  disabled: false,
  placement: undefined,
};

CreateListForm.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  placement: PropTypes.oneOf(['card', 'modal']),
};

export default CreateListForm;
