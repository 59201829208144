import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

import FocusTrap from 'components/FocusTrap';
import PortalModal from 'components/PortalModal';
import ModalCloseButton from 'components/wdx/buttons/ModalCloseButton';

import styles from 'components/wdx/ModalWithCloseButton/ModalWithCloseButton.scss';

const ModalWithCloseButton = pure(
  ({
    anchoredBottomSmall,
    children,
    className,
    closeModal,
    closeOnEscapeKey,
    disabledClose,
    focusTrapActive,
    fullHeightSmall,
    fullScreenTransitions,
    id,
    isOpen,
    modalDescriptionID,
    modalHeadingID,
    returnFocusOnDeactivate,
    testName,
    webViewId,
    webViewIdForOverlay,
  }) => {
    const elementId = id ? `#${id}` : null;
    return (
      <PortalModal
        anchoredBottomSmall={anchoredBottomSmall}
        className={className}
        closeOnEscapeKey={closeOnEscapeKey && !disabledClose}
        closeOnOverlayClick={false}
        fullHeightSmall={fullHeightSmall}
        fullScreenTransitions={fullScreenTransitions}
        modalDescriptionID={modalDescriptionID}
        modalHeadingID={modalHeadingID}
        onClose={closeModal}
        show={isOpen}
        testName={testName}
        webViewId={webViewId}
        webViewIdForOverlay={webViewIdForOverlay}
      >
        <FocusTrap
          active={focusTrapActive && isOpen}
          className={styles.focusTrap}
          focusTrapOptions={{
            escapeDeactivates: false,
            fallbackFocus: elementId,
            initialFocus: elementId,
            returnFocusOnDeactivate,
            clickOutsideDeactivates: true,
          }}
        >
          <ModalCloseButton
            close={closeModal}
            disabled={disabledClose}
            fullScreen={fullScreenTransitions}
          />
          {children}
        </FocusTrap>
      </PortalModal>
    );
  },
);

ModalWithCloseButton.propTypes = {
  anchoredBottomSmall: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  closeOnEscapeKey: PropTypes.bool,
  disabledClose: PropTypes.bool,
  focusTrapActive: PropTypes.bool,
  fullHeightSmall: PropTypes.bool,
  fullScreenTransitions: PropTypes.bool,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  modalDescriptionID: PropTypes.string,
  modalHeadingID: PropTypes.string,
  returnFocusOnDeactivate: PropTypes.bool,
  testName: PropTypes.string,
  webViewId: PropTypes.string,
  webViewIdForOverlay: PropTypes.string,
};

ModalWithCloseButton.defaultProps = {
  anchoredBottomSmall: false,
  className: null,
  closeOnEscapeKey: true,
  disabledClose: false,
  focusTrapActive: true,
  fullHeightSmall: false,
  fullScreenTransitions: false,
  id: null,
  isOpen: false,
  modalDescriptionID: null,
  modalHeadingID: null,
  returnFocusOnDeactivate: true,
  testName: 'ModalWithCloseButton',
  webViewId: null,
  webViewIdForOverlay: null,
};

ModalWithCloseButton.displayName = 'ModalWithCloseButton';

export default ModalWithCloseButton;
