import React from 'react';
import PropTypes from 'prop-types';

import { CircleAdd } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from 'components/Lists/CreateList/Button/Button.scss';

const CreateListButton = ({ children, onClick }) => (
  <button
    className={styles.addButton}
    data-testid="create-list-button"
    onClick={onClick}
    type="button"
  >
    <span className={styles.label}>
      <CircleAdd name="Add" className={styles.icon} />
      {children}
    </span>
  </button>
);

CreateListButton.defaultProps = {
  children: '',
  onClick: () => {},
};

CreateListButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default CreateListButton;
