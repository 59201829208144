import { connect } from 'react-redux';

import ListsModal from 'components/ListsModal/ListsModal';
import { hideListsModal } from 'redux/modules/shopping-lists/actions/hide-lists-modal';
import {
  getCreateListFormVisible,
  getListsModalProduct,
  getListsModalVisible,
} from 'redux/modules/shopping-lists/selectors';

export default connect(
  state => ({
    formVisible: getCreateListFormVisible(state),
    isOpen: getListsModalVisible(state),
    product: getListsModalProduct(state),
  }),
  dispatch => ({
    hideListsModal: () => dispatch(hideListsModal()),
  }),
)(ListsModal);
