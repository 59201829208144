import React from 'react';
import PropTypes from 'prop-types';

import CreateListForm from 'components/Lists/CreateList/Form';
import CreateListButton from 'components/Lists/CreateList/Button';

const CreateList = ({
  buttonLabel,
  createShoppingList,
  creating,
  formVisible,
  hideForm,
  lineNumber,
  placement,
  showForm,
}) => {
  const onSubmit = values => {
    createShoppingList({
      lineNumber,
      name: values.listName,
    }).then(hideForm);
  };

  return formVisible ? (
    <CreateListForm disabled={creating} onSubmit={onSubmit} placement={placement} />
  ) : (
    <CreateListButton onClick={showForm} placement={placement}>
      {buttonLabel}
    </CreateListButton>
  );
};

CreateList.defaultProps = {
  buttonLabel: '',
  creating: false,
  formVisible: false,
  lineNumber: undefined,
  placement: undefined,
};

CreateList.propTypes = {
  buttonLabel: PropTypes.string,
  createShoppingList: PropTypes.func.isRequired,
  creating: PropTypes.bool,
  formVisible: PropTypes.bool,
  hideForm: PropTypes.func.isRequired,
  lineNumber: PropTypes.string,
  placement: PropTypes.oneOf(['card', 'modal']),
  showForm: PropTypes.func.isRequired,
};

export default CreateList;
