import { connect } from 'react-redux';

import { createShoppingList } from 'redux/modules/shopping-lists/actions/create-shopping-list';
import { hideCreateListForm } from 'redux/modules/shopping-lists/actions/hide-create-list-form';
import { showCreateListForm } from 'redux/modules/shopping-lists/actions/show-create-list-form';

import {
  getListsModalLineNumber,
  getCreateListFormVisible,
  getShoppingListsCreating,
} from 'redux/modules/shopping-lists/selectors';

import CreateList from 'components/Lists/CreateList/CreateList';

export default connect(
  state => ({
    creating: getShoppingListsCreating(state),
    lineNumber: getListsModalLineNumber(state),
    formVisible: getCreateListFormVisible(state),
  }),
  {
    createShoppingList,
    hideForm: hideCreateListForm,
    showForm: showCreateListForm,
  },
)(CreateList);
