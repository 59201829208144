import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { setDisplayName } from 'recompose';

import CreateListForm from 'components/Lists/CreateList/Form/Form';

export default compose(
  setDisplayName('CreateListForm'),
  reduxForm({
    form: 'ListsModalCreateList',
    initialValues: {
      listname: '',
    },
  }),
)(CreateListForm);
