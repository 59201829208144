import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import urls from 'constants/urls';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';

import styles from 'components/ListsModal/ListModalButtons/ListModalButtons.scss';

const ListModalButtons = ({ hideListsModal }) => (
  <div className={styles.wrapper}>
    <div className={styles.button}>
      <LinkAsButton component={Link} to={urls.lists} label="View my lists" theme="secondary" />
    </div>
    <div className={styles.button}>
      <Button
        label="Finished"
        onClick={hideListsModal({ reason: 'Finished' })}
        theme="primary"
        type="button"
        width="full"
      />
    </div>
  </div>
);

ListModalButtons.defaultProps = {};

ListModalButtons.propTypes = {
  hideListsModal: PropTypes.func.isRequired,
};

ListModalButtons.displayName = 'ManageLists';

export default ListModalButtons;
