import { createSelector } from 'reselect';
import get from 'lodash/get';

import { getShoppingListById } from 'redux/modules/entities/selectors/shopping-lists';
import { getProducts } from 'redux/modules/entities/selectors/products';

const getShoppingLists = state => state.shoppingLists;
export const getCreateListFormVisible = state => state.shoppingLists.createListActive;

export const getListsModalLineNumber = createSelector(
  [getShoppingLists],
  shoppingLists => shoppingLists.modalLineNumber,
);

export const getListsModalProduct = createSelector(
  [getProducts, getListsModalLineNumber],
  (products = {}, lineNumber) => {
    let lineNumbersArr = [];
    let countLineNumberInList = 0;
    if (lineNumber?.includes(',')) {
      lineNumbersArr = lineNumber.split(',');
      lineNumbersArr.forEach(ln => {
        /* eslint-disable no-prototype-builtins */
        if (products.hasOwnProperty(ln)) {
          countLineNumberInList += 1;
        }
      });
      if (lineNumbersArr.length === countLineNumberInList) {
        return lineNumbersArr;
      }
    }
    return products[lineNumber];
  },
);

export const getListsModalProductIsInList = createSelector(
  [getListsModalProduct, getShoppingListById],
  (product, list) => {
    const items = get(list, 'items', []);
    if (Array.isArray(product)) {
      const itemLineNumbers = items.map(item => item.lineNumber);
      const isAdded = product.every(prod => itemLineNumbers.includes(prod));
      return isAdded;
    }

    const lineNumber = get(product, 'lineNumber');
    return items.some(item => item.lineNumber === lineNumber);
  },
);

export const getListsModalVisible = createSelector(
  [getShoppingLists],
  shoppingLists => shoppingLists.modalVisible,
);

export const getShoppingListsCreating = createSelector([getShoppingLists], shoppingLists =>
  get(shoppingLists, 'creating', false),
);

export const getShoppingListsFetched = createSelector([getShoppingLists], shoppingLists =>
  get(shoppingLists, 'fetched', false),
);
