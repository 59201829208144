import React from 'react';
import { bool, func, number, string } from 'prop-types';

import { OPTION_TYPES } from 'ingredients/forms/input-types';

import BoxedSelectionControl from 'ingredients/forms/BoxedSelectionControl';

import styles from 'components/ListsModal/ShoppingList/ShoppingList.scss';

const ShoppingList = ({ count, lineNumber, listId, name, member, toggle }) => (
  <li className={styles.listItem}>
    <BoxedSelectionControl
      checked={member}
      name={listId}
      onChange={() =>
        toggle({
          lineNumber,
          shoppingListId: listId,
        })
      }
      type={OPTION_TYPES.CHECKBOX}
      value={listId}
    >
      <div className={styles.label}>
        <span className={styles.name} data-cs-mask>
          {name}
        </span>
        <span className={styles.count}>
          {count} {`item${count !== 1 ? 's' : ''}`}
        </span>
      </div>
    </BoxedSelectionControl>
  </li>
);

ShoppingList.propTypes = {
  count: number,
  lineNumber: string,
  listId: string,
  member: bool,
  name: string,
  toggle: func.isRequired,
};

ShoppingList.defaultProps = {
  count: 0,
  lineNumber: '',
  listId: '',
  member: false,
  name: '(Untitled)',
};

export default ShoppingList;
