import { SHOPPING_LISTS_HIDE_MODAL } from 'redux/modules/shopping-lists/actions/types';
import { trackModalClosed } from 'analytics/modal-tracking';
import { getCustomerId } from 'redux/modules/sessions/selectors';
import { modalSeverityValues } from 'constants/modals';

export const hideListsModal =
  ({ reason } = {}) =>
  (dispatch, getState) => {
    trackModalClosed({
      id: 'shoppingListsModal',
      severity: modalSeverityValues.INFO,
      title: 'Add to list',
      customerId: getCustomerId(getState()),
      response: reason,
    });
    return dispatch({ type: SHOPPING_LISTS_HIDE_MODAL });
  };
