import React from 'react';
import PropTypes from 'prop-types';
import ShoppingList from 'components/ListsModal/ShoppingList';

const ShoppingLists = ({ listIds }) =>
  listIds.map(listId => <ShoppingList key={listId} listId={listId} />);

ShoppingLists.defaultProps = {
  listIds: [],
};

ShoppingLists.displayName = 'ShoppingLists';

ShoppingLists.propTypes = {
  listIds: PropTypes.arrayOf(PropTypes.string),
};

export default ShoppingLists;
