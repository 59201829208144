import { connect } from 'react-redux';

import {
  getShoppingListCount,
  getShoppingListName,
} from 'redux/modules/entities/selectors/shopping-lists';

import { toggleListMembership } from 'redux/modules/shopping-list/actions/toggle-list-membership';
import {
  getListsModalLineNumber,
  getListsModalProductIsInList,
} from 'redux/modules/shopping-lists/selectors';

import ShoppingList from 'components/ListsModal/ShoppingList/ShoppingList';

export default connect(
  (state, { listId }) => ({
    count: getShoppingListCount(state, listId),
    lineNumber: getListsModalLineNumber(state),
    loading: false,
    name: getShoppingListName(state, listId),
    member: getListsModalProductIsInList(state, listId),
  }),
  {
    toggle: toggleListMembership,
  },
)(ShoppingList);
