import { apiCallback } from 'api';

const definition = {
  service: 'shopping-lists',
  endpoint: 'shopping-lists',
  query: {
    summary: '${summary}',
  },
  verbs: ['get', 'post'],
  defaultVersion: 1,
};

export default {
  get: apiCallback(definition, 'get'),
  post: apiCallback(definition, 'post'),
};
