import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { trackModalOpened } from 'analytics/modal-tracking';
import { modalSeverityValues } from 'constants/modals';

import CreateList from 'components/Lists/CreateList';
import ListModalButtons from 'components/ListsModal/ListModalButtons';
import ModalWithCloseButton from 'components/wdx/ModalWithCloseButton';
import ShoppingLists from 'components/ListsModal/ShoppingLists';

import styles from 'components/ListsModal/ListsModal.scss';

const ListsModal = ({ formVisible, isOpen, hideListsModal, product, customerId }) => {
  useEffect(() => {
    if (isOpen) {
      trackModalOpened({
        id: 'shoppingListsModal',
        severity: modalSeverityValues.INFO,
        title: 'Add to list',
        customerId,
      });
    }
  }, [isOpen, customerId]);
  return (
    <ModalWithCloseButton
      closeModal={hideListsModal}
      fullHeightSmall
      isOpen={isOpen}
      returnFocusOnDeactivate={false}
    >
      <div className={styles.modal} data-test="lists-modal">
        <div className={styles.panels}>
          <div className={styles.headerPanel}>
            <h1 className={styles.heading}>Add to list</h1>
          </div>
          <div className={styles.scrollablePanel} data-scroll-target>
            <div className={styles.message}>
              Select an option below to add <span className={styles.name}>{product.name}</span> to a
              list:
            </div>
            <ul className={styles.lists}>
              <ShoppingLists />
            </ul>
            <div className={classNames(styles.lists, { [styles.formHidden]: !formVisible })}>
              <CreateList buttonLabel="Add to new list" />
            </div>
          </div>
          <div className={styles.buttonPanel}>
            <div className={styles.modalButtons}>
              <ListModalButtons />
            </div>
          </div>
        </div>
      </div>
    </ModalWithCloseButton>
  );
};

ListsModal.defaultProps = {
  customerId: undefined,
  formVisible: false,
  product: {
    lineNumber: '',
    name: '',
    thumbnail: '',
  },
};

ListsModal.propTypes = {
  customerId: PropTypes.string,
  formVisible: PropTypes.bool,
  hideListsModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    lineNumber: PropTypes.string,
    name: PropTypes.string,
    thumbnail: PropTypes.string,
  }),
};

export default ListsModal;
